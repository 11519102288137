@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url('HelveticaNeueCyr-Medium.eot');
	src: local('HelveticaNeueCyr-Medium'),
		url('HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
		url('HelveticaNeueCyr-Medium.woff') format('woff'),
		url('HelveticaNeueCyr-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}


@font-face {
	font-family: 'HelveticaNeueCyr';
	src: url('HelveticaNeueCyr-Bold.eot');
	src: local('HelveticaNeueCyr-Bold'),
		url('HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
		url('HelveticaNeueCyr-Bold.woff') format('woff'),
		url('HelveticaNeueCyr-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}


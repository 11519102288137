.button {
    border: none;
    width: 234px;
    height: 60px;
    margin: 30px auto;
    text-align: center;
    font-size: 22px;
    border-radius: 14px;
    background-color: white;
    box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2), 0 0 15px 2px rgba(0, 0, 0, 0.5);
    font-weight: bold;
    display: block;
    text-decoration: none;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    &:active {
        border: 3px solid rgb(179, 1, 1);
    }
}
#video {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: relative;
    font-family: HelveticaNeueCyr;
    .top_infoblock {
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 28px;
        padding: 0 20px;
        margin: 65px 0;
        box-sizing: border-box;
        background: #fff;
        z-index: 1;
    }
    .bottom_infoblock {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        font-size: 28px;
        background: #fff;
        z-index: 1;
    }
}
#connection {
  font-family: HelveticaNeueCyr;
  h1 {
    font-size: 80px;
    text-align: center;
  }
  h2 {
    font-size: 130px;
    text-align: center;
    color: #b30101;
  }
  h3 {
    position: absolute;
    top: 0;
    right: 15px;
    font-size: 28px;
  }
  button {
    font-size: 22px;
    bottom: 15px;
    right: 50%;
    position: fixed;
    border: 3px solid #b30101;
    border-radius: 14px;
    background-color: white;
    box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
    width: 300px;
    margin-right: -150px;
    height: 50px;
  }
  .img_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    img {
      display: block;
      /* height: 60%; */
      box-shadow: 0 0 13.3px 0.7px rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      width: 50%;
      padding: 40px;
    }
  }
}
